<template>
  <div>
    <nav class="bg-blue-500">
      <div class="container px-4 mx-auto">
        <div class="flex items-center justify-between h-16">
          <!-- Logo -->
          <div class="flex-shrink-0">
            <a href="#" class="text-white font-semibold text-xl">Financial App</a>
          </div>

          <!-- Mobile Menu Button -->
          <div class="flex md:hidden">
            <button
              class="text-white hover:text-blue-200 focus:outline-none focus:text-blue-200"
              @click="toggleMobileMenu"
            >
              <svg
                class="h-6 w-6 fill-current"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  v-if="!showMobileMenu"
                  class="text-white"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M4 6h16V5H4v1zm0 4h16v-1H4v1zm0 4h16v-1H4v1z"
                />
                <path
                  v-if="showMobileMenu"
                  class="text-white"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M4 6h16V5H4v1zm0 6h16v-1H4v1zm0 6h16v-1H4v1z"
                />
              </svg>
            </button>
          </div>

          <!-- Navbar links -->
          <div
            :class="{
              'hidden': !showMobileMenu,
              'md:flex': true,
              'flex-col': true,
              'items-start': true,
              'mt-4': true
            }"
          >
            <a
              href="#"
              class="text-white hover:text-blue-200 md:ml-4"
            >
              Home
            </a>
            <a
              href="#"
              class="text-white hover:text-blue-200 md:ml-4"
            >
              Transactions
            </a>
            <a
              href="#"
              class="text-white hover:text-blue-200 md:ml-4"
            >
              Reports
            </a>
            <a
              href="#"
              class="text-white hover:text-blue-200 md:ml-4"
            >
              Settings
            </a>
          </div>
        </div>
      </div>
    </nav>
    <NuxtPage v-if="user" />
    <Auth v-else />
  </div>
</template>

<script>
export default {
  name: 'Navbar',
  data() {
    return {
      showMobileMenu: false
    }
  },
  methods: {
    toggleMobileMenu() {
      this.showMobileMenu = !this.showMobileMenu
    }
  }
}
</script>

<script setup>
const user = useSupabaseUser()
</script>

<style scoped>
/* Add any custom styles here */
</style>
